import React, { useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { LocalizationProvider, Localized } from "@fluent/react";

import { getQueryParam } from "@kikoff/client-utils/src/dom";
import Logo from "@kikoff/components/src/v1/brand/Logo";
import KButton from "@kikoff/components/src/v1/buttons/KButton";
import Collapsible from "@kikoff/components/src/v1/containers/Collapsible";
import KLink from "@kikoff/components/src/v1/navigation/KLink";
import { isClient } from "@kikoff/utils/src/general";
import format from "@kikoff/utils/src/string/format";

import facebookIcon from "@asset/icons/facebook_black.svg";
import instagramIcon from "@asset/icons/instagram_black.svg";
import { ContainerButton } from "@component/buttons/buttons";
import PlusExpander from "@component/expanders/plus_expander";
import I18nToggle from "@component/l10n/toggle";
import {
  blogUrl,
  bpoContactNumer,
  getCreditAccountMentalModelName,
  getKikoffCreditAccountMentalModelName,
  getKikoffCreditServiceGenericMentalModelName,
} from "@src/constants";
import { track } from "@util/analytics";
import { getLocaleCookie, l10nResources } from "@util/l10n";

import styles from "./styles.module.scss";

const l10n = l10nResources({
  en: `footer-pricing = Pricing
footer-build-credit = Build Credit
footer-rent-reporting = Rent Reporting
footer-cash-card = Secured Credit Card
footer-download = Download
footer-faqs = FAQs
footer-about-us = About Us
footer-affiliate = Affiliate
footer-influencers = Influencers
footer-careers = Careers
footer-blog = Blog
footer-terms = Terms
footer-privacy = Privacy
footer-ccb-privacy = CCB Privacy Notice
footer-ca-privacy = California Privacy Policy
footer-do-not-sell = Do Not Sell or Share My Personal Information
disclaimer-money-back = 45 day money back guarantee requires completing cancellation of the Kikoff Credit Service and/or any other Kikoff Store item(s) within 45 days, starting from the day of the first purchase of the Kikoff Credit Service or any Kikoff Store product(s). Cancellations handled through customer service may take several business days to process but cancellations through the app or website are processed the same day. Refunds typically process in 3-5 business days. The money back guarantee is limited to up to one use per person.
disclaimer-members-section = Based on Kikoff customers who used the {$creditAccount} product and made on-time payments, and didn’t add non-Kikoff delinquencies/collections to their credit profile. This data is based on observed Equifax Vantage 3.0 credit score changes, and is current as of November 2022.
get-started = Get started
footer-licenses = Licenses
`,
  es: `footer-pricing = Precios
footer-build-credit = Construye Crédito
footer-rent-reporting = Informes de alquiler
footer-cash-card = Tarjeta de crédito asegurada Kikoff
footer-download = Descargar
footer-faqs = Preguntas Frecuentes
footer-about-us = Sobre nosotros
footer-affiliate = Afiliado
footer-influencers = Influencers
footer-careers = Carreras
footer-blog = Blog
footer-terms = Términos
footer-privacy = Privacidad
footer-ccb-privacy = CCB Privacidad Notice
footer-ca-privacy = Privacidad California
footer-do-not-sell = No Venda ni Comparta mi información
disclaimer-money-back = La garantía de devolución de dinero de 45 días requiere completar la cancelación del Servicio de crédito Kikoff y/o cualquier otro artículo de la Tienda Kikoff dentro de los 45 días, a partir del día de la primera compra del Servicio de crédito Kikoff o de cualquier producto de la Tienda Kikoff. Las cancelaciones manejadas a través del servicio de atención al cliente pueden tardar varios días hábiles en procesarse, pero las cancelaciones a través de la aplicación o el sitio web se procesan el mismo día. Los reembolsos normalmente se procesan en 3 a 5 días hábiles. La garantía de devolución de dinero está limitada a un máximo de un uso por persona.
disclaimer-members-section = Basado en clientes de Kikoff que usaron el producto {$creditAccount} y realizaron pagos a tiempo, y no agregaron morosidad/cobros que no son de Kikoff a su perfil de crédito. Estos datos se basan en los cambios observados en la puntuación de crédito de Equifax Vantage 3.0 y están actualizados a noviembre de 2022.
get-started = Empezar
footer-licenses = Licencias
`,
});

function I18nDisclaimer() {
  const locale = getLocaleCookie();
  const router = useRouter();
  if (router.asPath.split("?")[0] !== "/" || locale === "en") {
    return null;
  }

  return (
    <div className={styles["i18n-disclaimer"]}>
      Al solicitar Kikoff, usted acepta recibir divulgaciones de apertura de la
      cuenta y términos y condiciones importantes de la cuenta en inglés. Si el
      inglés no es su idioma principal y tiene dificultades para leer o
      comunicarse de manera efectiva en inglés, es posible que necesite un
      intérprete en orden para tomar una decisión informada. Tenga en cuenta que
      las comunicaciones adicionales con Kikoff estarán en inglés, no en
      español. Esto incluye, entre otros; correos electrónicos, alertas,
      mensajes de texto y contacto con el servicio de atención al cliente.
    </div>
  );
}

function FaqLegalFootnotes() {
  return (
    <>
      <sup>1</sup> {getKikoffCreditServiceGenericMentalModelName(false)} is
      financed by a $750 line of credit on the{" "}
      {getKikoffCreditAccountMentalModelName()}. Additional transactions may
      affect the monthly payment. $750 line of credit can only be used in the
      Kikoff store.
      <br />
      <sup>2</sup> A new customer with a $60 purchase will have less than 10%
      utilization ($60 purchase on $750 line of credit). Utilization rates will
      fluctuate based on the account balance at any given time.
      <br />
      <sup>3</sup> Payments and non-payments will be reported to the major
      credit bureaus.
    </>
  );
}

function TiktokDisclaimer() {
  if (!isClient || getQueryParam("utm_source") !== "tiktok") {
    return null;
  }

  return (
    <div>
      This site is not a part of the TikTok website or Bytedance Ltd.
      Additionally, this site is NOT endorsed by TikTok in any way. TikTok is a
      trademark of Bytedance Ltd.
    </div>
  );
}

function HomepageLegalFootnote() {
  return (
    <>
      <sup>1</sup> Based on real Kikoff members who made a purchase with the
      Kikoff Credit Account between January 2021 and March 2024 with starting
      scores below 600 and made their first payment saw an average Equifax
      Vantage 3.0 score increase of 28 points after their first month. Late
      payment may negatively impact your credit score. Individual results may
      vary.
      <br />
      <br />
      <sup>2</sup> {l10n.getString("disclaimer-money-back")}
      <br />
      <br />
      <sup>3</sup>{" "}
      {l10n.getString("disclaimer-members-section", {
        creditAccount: getCreditAccountMentalModelName(),
      })}
    </>
  );
}

function LegalFootnotes({ legalFootnotes }) {
  const router = useRouter();
  const pathWithoutQueryParams = router.asPath.split("?")[0];
  const [newMexicoTextCollapsed, setNewMexicoTextCollapsed] = useState(true);
  switch (pathWithoutQueryParams) {
    case "/faq":
      legalFootnotes = <FaqLegalFootnotes />;
      break;
    case "/":
      legalFootnotes = <HomepageLegalFootnote />;
      break;
    default:
  }

  if (!legalFootnotes) {
    return null;
  }

  return (
    <>
      <hr className={styles["footnote-hr"]} />
      <p className="text:legal-primary">{legalFootnotes}</p>
      <p className="text:legal-primary">
        Kikoff Inc NMLS ID# 1930930 |{" "}
        <KLink href="/licenses" variant="underlined">
          {l10n.getString("footer-licenses")}
        </KLink>
      </p>
      <ContainerButton
        onClick={() => {
          setNewMexicoTextCollapsed(!newMexicoTextCollapsed);
          track("expanded NM legal text");
        }}
        className={styles["legal-toggle"]}
      >
        <div className="text:legal-primary">New Mexico residents only</div>
        <PlusExpander
          className={styles["legal-expander"]}
          expand={!newMexicoTextCollapsed}
          size={8}
        />
      </ContainerButton>
      <Collapsible collapse={newMexicoTextCollapsed}>
        <p className="text:legal-primary">
          New Mexico residents only – TO REPORT A PROBLEM OR COMPLAINT WITH THIS
          LENDER YOU MAY WRITE OR CALL Compliance Director at P.O. Box 40070,
          Reno, NV 89504; (775) 993-6992.; or at support@kikoff.com. This lender
          is licensed and regulated by the New Mexico Regulation and Licensing
          Department, Financial Institutions Division, P.O. Box 25101, 2550
          Cerrillos Road, Santa Fe, New Mexico 87504. To report any unresolved
          problems or complaints, contact the division by telephone at (505)
          476-4885 or visit the website
          http://www.rld.state.nm.us/financialinstitutions/.
        </p>
      </Collapsible>
    </>
  );
}

interface LandingFooterProps {
  legalFootnotes?: React.ReactNode | React.FC;
}

const LandingFooter: React.FC<LandingFooterProps> = ({ legalFootnotes }) => {
  const router = useRouter();

  return (
    <footer className={styles["landing-footer"]}>
      <LocalizationProvider l10n={l10n}>
        <div className={styles["main-contents-row"]}>
          <div className={styles["left-side"]}>
            <Logo
              className={styles["footer-logo"]}
              variant="black"
              size="54px"
            />
            <div className={styles["get-started-section"]}>
              <KButton
                size="standard"
                variant="black"
                onClick={() => {
                  track("footer: click 'get started' button");
                  router.push("/onboarding");
                }}
              >
                {l10n.getString("get-started")}
              </KButton>
            </div>
            <div className={styles["copyright"]}>
              © {new Date().getFullYear()} Kikoff Inc.
            </div>
          </div>
          <div className={styles["middle-menu"]}>
            <div className={styles["menu-column"]}>
              <a href="/credit-account">
                <Localized id="footer-build-credit" />
              </a>
              <a href="/rent-reporting">
                <Localized id="footer-rent-reporting" />
              </a>
              <a href="/kikoff-card">
                <Localized id="footer-cash-card" />
              </a>
              <a href="/download">
                <Localized id="footer-download" />
              </a>
              <a target="_blank" href="/faq">
                <Localized id="footer-faqs" />
              </a>
              <a href="/about">
                <Localized id="footer-about-us" />
              </a>
              <a
                href="https://kikoff.grin.live/member-creator-application"
                onClick={() => {
                  track.tap("Footer - Influencer Form");
                }}
                target="_blank"
                rel="noreferrer"
              >
                <Localized id="footer-influencers" />
              </a>
              <a
                href="https://kikoffsupport.zendesk.com/hc/en-us/articles/22697015878541-Kikoff-Credit-Account-Affiliate-Program"
                onClick={() => {
                  track("footer: click 'Affiliate' link");
                }}
                target="_blank"
                rel="noreferrer"
              >
                <Localized id="footer-affiliate" />
              </a>
              <a>{format.phone(bpoContactNumer)}</a>
            </div>
            <div className={styles["menu-column"]}>
              <a href="/careers">
                <Localized id="footer-careers" />
              </a>
              <a target="_blank" rel="noreferrer" href={blogUrl("/")}>
                <Localized id="footer-blog" />
              </a>
              <a target="_blank" href="/terms.pdf">
                <Localized id="footer-terms" />
              </a>
              <a target="_blank" href="/privacy-policy.pdf">
                <Localized id="footer-privacy" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.coastalbank.com/privacy-notice/"
              >
                <Localized id="footer-ccb-privacy" />
              </a>
              <a target="_blank" href="/ca-privacy-policy.pdf">
                <Localized id="footer-ca-privacy" />
              </a>
              <a target="_blank" href="/do-not-sell.pdf">
                <Localized id="footer-do-not-sell" />
              </a>
              <div className={styles["l10n-section"]}>
                <I18nToggle variant="light" />
              </div>
            </div>
          </div>

          <div className={styles["social-and-localization-wrapper"]}>
            <a
              className={styles["social-link"]}
              href="https://www.instagram.com/getkikoff/"
              rel="noreferrer"
              target="_blank"
            >
              <Image src={instagramIcon} alt="Instagram" />
            </a>
            <a
              className={styles["social-link"]}
              href="https://www.facebook.com/GetKikoff/"
              rel="noreferrer"
              target="_blank"
            >
              <Image src={facebookIcon} alt="Facebook" />
            </a>
          </div>
        </div>

        <div className={styles["disclaimer-rows"]}>
          <TiktokDisclaimer />
          <I18nDisclaimer />
          <LegalFootnotes legalFootnotes={legalFootnotes} />
        </div>
      </LocalizationProvider>
    </footer>
  );
};

export default LandingFooter;
