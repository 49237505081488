import React from "react";

import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./plus_expander.module.scss";

interface PlusExpanderProps {
  expand: boolean;
  size?: React.CSSProperties["width"];
  thickness?: React.CSSProperties["width"];
  className?: string;
  round?: boolean;
}

const PlusExpander: React.FC<PlusExpanderProps> = ({
  expand,
  size = 16,
  className,
  thickness = 2,
  round = false,
}) => {
  return (
    <div
      className={combineClasses(styles["plus-expander"], className)}
      style={{ width: size, height: size }}
    >
      <div
        style={{
          width: size,
          height: thickness,
          top: `calc(50% - (${
            typeof thickness === "number" ? `${thickness}px` : thickness
          } / 2))`,
          borderRadius: round && thickness,
        }}
      />
      <div
        style={{
          height: expand ? 0 : size,
          width: thickness,
          left: `calc(50% - (${
            typeof thickness === "number" ? `${thickness}px` : thickness
          } / 2))`,
          top: expand ? "50%" : 0,
          borderRadius: round && thickness,
        }}
      />
    </div>
  );
};
export default PlusExpander;
